import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import Add from "@material-ui/icons/Add";
import Remove from "@material-ui/icons/Remove";
import { Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import PropTypes from "prop-types";
import ReactHTMLParser from "html-react-parser";
import { makeStyles } from "@material-ui/styles";
import ActionConstants from "../../../src/action-constants/app-actions";
import Error from "../../common/error";
import styles from "./styles/mobile-footer-accordian.module.scss";

const useStyles = makeStyles({
  headerContent: {
    fontWeight: 500,
    fontSize: "14px",
    marginLeft: "48px",
  },
  moreContent: {
    float: "left",
    width: "100%",
    padding: "25px",
    boxSizing: "border-box",
    borderTop: "1px solid #d5d5d5",
    color: "#222",
    "& h2,h3": {
      fontSize: "18px",
      marginBottom: "10px",
      color: "#222",
      fontWeight: "500",
    },
    "& h6": {
      fontWeight: "500",
    },
    "& p ": {
      textAlign: "justify",
      fontSize: "14px",
      lineHeight: "22px",
      color: "#666",
      marginBottom: "20px",
    },
  },
});
/**
 * This is a functional component representing an accordian component
 *
 * @param {object} props .
 * @returns {React.ReactElement} the JSX for the component
 */
function FooterAccordion(props) {
  const [expand, setExpand] = useState(1);
  const classes = useStyles();
  const { accordionData, heading } = props;

  /**
   * This method handles accordian expansion
   */
  const handleClick = () => {
    if (expand === 0) setExpand(1);
    else setExpand(0);
  };
  /**
   * This method returns the main JSX to render
   *
   * @returns {React.ReactElement}.
   */
  const getJSXToRender = () => {
    const renderJSX = accordionData;
    if (renderJSX === ActionConstants.ECONNABORTED) return <Error />;
    return ReactHTMLParser(String(accordionData), { trim: true });
  };
  return (
    <aside className={styles["more-info"]}>
      <Accordion onClick={handleClick}>
        <AccordionSummary
          className={styles["header-container"]}
          expandIcon={
            expand === 1 ? <Add className={styles["header-icon"]} /> : <Remove className={styles["header-icon"]} />
          }
          aria-controls="area-content"
        >
          <Typography className={classes.headerContent}>{heading}</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.moreContent}>
          <Typography component="div">{getJSXToRender()}</Typography>
        </AccordionDetails>
      </Accordion>
    </aside>
  );
}

FooterAccordion.propTypes = {
  accordionData: PropTypes.string,
  heading: PropTypes.string,
};

FooterAccordion.defaultProps = {
  accordionData: "",
  heading: "",
};
export default FooterAccordion;
