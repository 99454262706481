import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useRouter } from "next/router";
import styles from "./styles/error.module.scss";
import callClevertapEvent, { getPageNameForCleverTap } from "../../src/analytics/clevertapUtility";
import { cleverTapCommonEvents, cleverTapCommonWords } from "../../src/analytics/clevertapConstants";
import useClevertapSetup from "../hooks/analytics/clevertap/useCleverTapSetup";

/**
 * Display error message if api timeout expires
 *
 * @returns {React.ReactElement} - React component
 */
const Error = () => {
  const router = useRouter();
  const { isMobile } = useSelector((state) => state.appConfigs);
  useClevertapSetup();

  useEffect(() => {
    const pathname = window.location?.pathname;
    const path = pathname?.substring(1);

    const cleverTapData = {
      page_name: path,
      page_type: getPageNameForCleverTap(),
      error_code: "500",
      validation_for: cleverTapCommonWords.SOMETHING_WENT_WRONG,
      validation_text: cleverTapCommonWords.SOMETHING_WENT_WRONG,
    };
    callClevertapEvent(cleverTapCommonEvents.validationViewed, cleverTapData);
  }, [router]);

  return (
    <div className={`${styles["error-message"]} ${!isMobile && styles["error-message-container"]}`}>
      <img src={`${!isMobile ? "/d" : ""}/assets/images/something-went-wrong.png`} alt="something went wrong" />
      {isMobile && <p>Something Went Wrong!</p>}
      <button type="button" onClick={() => window.location.reload()}>
        Try again
      </button>
    </div>
  );
};
export default Error;
